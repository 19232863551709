<template>
  <div>
    <p class="alert-danger p-3 text-center fw-bold">後台人員專屬</p>
    <!-- 切換同步留言版本 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">切換同步留言版本</p>
      <div class="select-radio">
        <label v-for="data in syncCommentsType" :key="data.id">
          <input
            type="radio"
            name="syncCommentsType"
            :checked="data.selected"
            @click="
              updateConfiguration('p_fb', 'syncCommentsType', data)
            "
          />
          <span class="radio-style" v-if="data.value === 'old'">舊版</span>
          <span class="radio-style" v-else-if="data.value === 'new'">新版</span>
        </label>
      </div>
    </div>
    <!-- 是否顯示 "一鍵合併 FB 虛擬顧客" 功能 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">是否顯示 "一鍵合併 FB 虛擬顧客" 功能</p>
      <div class="select-radio">
        <label v-for="data in mergeFbExtensionVirtualUser" :key="data.id">
          <input
            type="radio"
            name="mergeFbExtensionVirtualUser"
            :checked="data.selected"
            @click="
              updateConfiguration('p_participant', 'mergeFbExtensionVirtualUser', data)
            "
          />
          <span class="radio-style" v-if="data.value === 'show'">顯示</span>
          <span class="radio-style" v-else-if="data.value === 'hide'">隱藏</span>
        </label>
      </div>
    </div>
    <!-- 一鍵合併虛擬顧客一次合併數量 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">一鍵合併虛擬顧客一次合併數量</p>
      <div class="form-floating mb-3">
        <input
          onwheel="this.blur()"
          type="number"
          class="form-control"
          id="mergeFbExtensionVirtualUserCount"
          placeholder="數量"
          max="90"
          v-model="mergeFbExtensionVirtualUserCount[0].value"
          pattern="[0-9]*"
          oninput="this.value=this.value.replace(/\D/g,'')"
        />
        <label for="mergeFbExtensionVirtualUserCount">數量</label>
      </div>
      <div class="text-end">
        <button
          class="tw-btn tw-btn-success"
          @click="
            updateConfiguration(
              'p_participant',
              'mergeFbExtensionVirtualUserCount',
              mergeFbExtensionVirtualUserCount[0]
            )
          "
        >
          儲存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 此頁面試給後台人員用的 (目的是要可以控制該賣場同步留言方式是要用新版還是舊版)
  data() {
    return {}
  },
  created() {
    this.getPrefer()
  },
  computed: {
    // 新舊版本 FB 留言同步
    syncCommentsType() {
      return this.$store.state.Prefer.p_fb.syncCommentsType;
    },
    // 是否顯示 "一鍵合併 FB 虛擬顧客" 功能
    mergeFbExtensionVirtualUser() {
      return this.$store.state.Prefer.p_participant.mergeFbExtensionVirtualUser;
    },
    // 一鍵合併虛擬顧客一次合併數量
    mergeFbExtensionVirtualUserCount() {
      return this.$store.state.Prefer.p_participant.mergeFbExtensionVirtualUserCount;
    },
  },
  methods: {
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(category, item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category,
        item: item,
        data: data
      })
    },
  },
}
</script>